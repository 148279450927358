import React from 'react'
import { graphql } from 'gatsby'
import HeroComponent from '../components/HeroComponent/HeroComponent'
import { RichText } from '../components/Shared/RichText/RichText'
import ContactUsCards from '../components/ContactUsCards/ContactUsCards'
import Seo from '../components/seo'
import { useUrl } from '../fragments/hooks/useUrl'
import Breadcrum from '../components/Breadcrum/Breadcrum'
const PageContact = ({
  location,
  pageContext,
  data: {
      contentfulPageCustom,
      allContentfulPageCustom: {
          nodes
      }
  }
}) => {
  const{heroImage, description, contactUsOptions, additionalText, slug, seoTitle, seoDescription, breadcrum, pageTitle} = contentfulPageCustom
  const lang = useUrl()
  return (
    <section id = 'PageContact'>
      <Seo
        slug={slug}
        title={seoTitle}
        description={seoDescription && seoDescription.seoDescription}
        node_locale={pageContext.node_locale}
        lang={lang}
        hrefLangs={nodes}
        />
      {breadcrum && <Breadcrum data ={breadcrum} title = {pageTitle}/>}
      <HeroComponent data = {heroImage}/>
      <div className='container container-lg'>
        <div className='contact-us-desc'>
          <RichText content={description} />
        </div>
      </div>
      <ContactUsCards gridItems = {contactUsOptions}/>
      <div className='container container-lg'>
        {additionalText && <div className='contact-us-additional-desc'>
          <RichText content={additionalText} />
        </div>}
      </div>
    </section>
  )
}

export const PagePageContactQuery = graphql`
    query pagePageContact($id:String!, $contentful_id: String! ){
        contentfulPageCustom(id:{eq:$id}){
            seoTitle
            seoDescription{
              seoDescription
            }
            pageTitle
            node_locale
            description{
              raw
            }
            breadcrum {
              title
              slug
            }
            slug
            heroImage {
              title
              heroImage {
                gatsbyImageData
                title
              }
              heroImageMobile {
                gatsbyImageData
                title
              }
            }
            contactUsOptions {
              title
              image {
                gatsbyImageData
                title
              }
              description {
                raw
              }
              cta{
                title
                slug
              }
            }
            additionalText {
              raw
            }
        }
        allContentfulPageCustom(filter: {contentful_id: {eq: $contentful_id}, id: {ne: $id}, slug: { ne: null }}){
            nodes {
                slug
                node_locale
            }
        }
    }
`
export default PageContact